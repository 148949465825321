import React, { useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { DeleteOutlined, EditOutlined, CloseOutlined, MenuOutlined, PhoneFilled, PictureFilled, SearchOutlined } from '@ant-design/icons';
import { getAll, createMaterial, updateMaterial, deleteMaterial } from 'api/api-materials';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, Badge } from 'antd';
import MaterialForm from './MaterialForm';
import ImportMaterialForm from './ImportMaterialForm';
import config from 'api/config';


const confirm = Modal.confirm;


const ActionCell = ({ item, onChange }: any) => {
	const [isRequesting, setIsRequesting] = useState(false);
	const onClickBtn = () => {

		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				deleteMaterial(item.ID).then((res) => {
					setIsRequesting(false);
					onChange();
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	
	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<DeleteOutlined />} onClick={onClickBtn} />
		</Space>
	);
};

const Index = () => {
	const [tableData, setTableData] = useState<any>([]);
	const [expandedKeys, setExpancedKeys] = useState<any>([0]);
	const [isRquesting, setIsRequesting] = useState(false);
	const [visibleImport3DModal, setVisibleImport3DModal] = useState(false);
	const searchInput = useRef<any>();
	const [state, setState] = useState({
		searchText: '',
		searchedColumn: '',
	});

	const loadTable = () => {
		getAll().then((res) => {
			console.log('ProductAPI ===>', res.body);
			setTableData(res.body);
		});
	};

	const getColumnSearchProps = (dataIndex:any) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
					Search
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setState({
								searchText: selectedKeys[0],
								searchedColumn: dataIndex,
							});
						}}
					>
						Filter
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered:boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value:string, record:any) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: (visible:boolean) => {
			if (visible) {
				setTimeout(() => searchInput.current.select(), 100);
			}
		},
		render: (text:string) =>
			state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns:any = [
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
			...getColumnSearchProps('label')
		},
		{
			title: 'Material ID',
			dataIndex: 'ID',
			key: 'ID',
			...getColumnSearchProps('ID')
		},
		{
			title: 'MetaData Material',
			dataIndex: 'Material',
			key: 'Material',
			...getColumnSearchProps('Material')
		},
		{
			title: 'MetaData Group',
			dataIndex: 'Group',
			key: 'Group',
			...getColumnSearchProps('Group')
		},
		{
			title: 'MetaData Part',
			dataIndex: 'Part',
			key: 'Part',
			...getColumnSearchProps('Part')
		},
		{
			title: 'Icon',
			dataIndex: 'photo_id',
			key: 'photo_id',
			render:(text:string, record:any)=>{
				if(record.photo){
					return <img  src = {`${config.host}${record.photo.url}`} width = "50" height = "50" style = {{borderRadius:'50%'}} />;
				}
				return <span style = {{
					display: 'flex',
					width: '50px',
					height: '50px',
					alignItems: 'center',
					justifyContent: 'center',
					border: 'solid 1px #ccc',
					borderRadius: '50%',
				}}><PictureFilled /></span>;

			}
		},
		{
			title: 'Action',
			key: 'action',
			// width: '20%',
			render: (text: string, record: any) => (
				<ActionCell item={record} onChange={() => loadTable()} />
			),
		},
	];

	useEffect(() => {
		loadTable();
	}, []);

	// const onCreateNewModelHome = () => {
	// 	const newData = {
	// 		id: 0,
	// 		title:'New Material',
	// 		sayduck_tags:[]
	// 	};
	// 	setTableData([newData, ...tableData]);
	// 	setExpancedKeys([newData.id, ...expandedKeys]);
	// };

	const handleSearch = (selectedKeys:any, confirm:()=>void, dataIndex:any) => {
		confirm();
		setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	const handleReset = (clearFilters:()=>void) => {
		clearFilters();
		setState({ ...state, searchText: ''});
	};


	return (
		<>
			<Card title = "Materials" extra = {<><Button onClick={()=>{setVisibleImport3DModal(true);}} type = "primary">Add More</Button></>} >
				{/* {
				tableData.map((ele:any, index)=>(<MaterialForm dataId = {ele.id} />))
			} */}
				<Table columns={columns} dataSource={tableData} expandable={{
					expandIconColumnIndex: 6,
					expandedRowKeys: expandedKeys,
					expandIcon: ({ expanded, onExpand, record }) =>
						expanded ? (
							<Button onClick={e => {
								const newArr = expandedKeys.filter((ele:any)=>ele !=  record._id);
								setExpancedKeys(newArr);
								onExpand(record, e);
								onExpand(record, e);
							}} icon = {<CloseOutlined/>} />
						) : (
							<Button onClick={e => {
								setExpancedKeys([expandedKeys, record._id]);
								onExpand(record, e);
							}} icon = {<EditOutlined />} />
						),
					expandedRowRender: record => <MaterialForm data = {record} onChange = {(newId)=>{
						if(newId){
							setExpancedKeys([...expandedKeys, newId]);
						}
						loadTable();
					}}/>,
				}}
				rowKey = "_id"
				pagination = {{showSizeChanger:true, pageSizeOptions:['10','20','50', '100'], defaultPageSize:100}}
				/>
			</Card>

			<ImportMaterialForm open = {visibleImport3DModal} onClose = {(reload)=>{
				if(reload){
					loadTable();
				}
				setVisibleImport3DModal(false);
			}} />
		</>
	);
};

export default Index;
