import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Dropdown, Button, Card, Form, Input, Select, notification, Checkbox } from 'antd';
import { getUserDetailById, createUser, updateUser } from 'api/api-users';
import useOptions from 'modules/options/options.hook';

import { PATHS } from 'constants/routes';

const Index = () => {

	const { userId } = useParams();
	const [form] = Form.useForm();

	const { options: { builders, tags }} = useOptions();

	const [userDetail, setUserDetail] = useState<any>({builder: 'Metricon Homes'});

	const [isRquesting, setIsRequesting] = useState(false);
	

	useEffect(()=>{
		if(userId){
			getUserDetailById(userId).then((res:any)=>{
				setUserDetail(res.body);
			}).catch((err:any)=>{
				console.log('error ==>', err);
			});
			
		}
	}, [userId]);

	useEffect(()=>{
		form.setFieldsValue(userDetail);
	}, [userDetail]);

	const submitForm = () => {
		form.submit();
	};

	const onSubmitForm = async (values:any) => {
		if(values.is_vic_region == undefined) values.is_vic_region = false;
		setIsRequesting(true);
		if(parseInt(userId || '0') == 0){
			//create new user
			const response = await createUser(values);

			notification.open({
				type:'success',
				message: 'Message',
				description:'Created succesfully',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
			window.location.href = `${PATHS.DASHBOARD}${PATHS.USERS}/${response.body.insertId}`;
			setIsRequesting(false);

		}else{
			//update user
			await updateUser(userId, values);
			
		}
		setIsRequesting(false);

	};

	return (
		<Card title = {parseInt(userId || '0') > 0?userDetail.name:'New User'} extra = {<Button type = "primary" onClick = {submitForm} loading = {isRquesting}>Save</Button>}>
			<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitForm}>
				<Form.Item
					label="Consultant Name"
					name="name"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="User Name"
					name="username"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="User Email"
					name="email"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Password"
					name="password"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					label="Builder"
					name="builder"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input disabled/>
				</Form.Item>

				<Form.Item
					label="State"
					name="location_tag_id"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						{tags.filter((ele:any)=>ele.tag_type == 'state').map((ele:any)=><Select.Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Select.Option>)}
					</Select>
				</Form.Item>

				<Form.Item
					label="Regional VIC"
					valuePropName='checked'
					name="is_vic_region"
				>
					<Checkbox /> 
				</Form.Item>


				<Form.Item
					label="Status"
					name="status"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						<Select.Option value = 'active'>Active</Select.Option>
						<Select.Option value = 'pending'>Pending</Select.Option>
						<Select.Option value = 'blocked'>Blocked</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="User Type"
					name="role"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						<Select.Option value = 'limit access'>Limit Access</Select.Option>
						<Select.Option value = 'all access'>All Access</Select.Option>
					</Select>
				</Form.Item>

			</Form>
		</Card>
	);
};

export default Index;