import React, { useEffect, useState, useRef } from 'react';
import { Layout, Menu, Breadcrumb, Dropdown, Button, Card, Form, Input, Select, Checkbox, notification, Badge } from 'antd';
import GalleryFrom from './GalleryFrom';
import { createModelHome, getModelHomeById, updateModelHome } from 'api/api-model-homes';

import { PATHS } from 'constants/routes';

import useOptions from 'modules/options/options.hook';
import useGallery from 'modules/gallery/gallery.hook';

const { Option } = Select;

interface Props {
	dataId:any,
	onChange: (newId?:any)=>void
}

const Index = ({ dataId, onChange }:Props) => {

	const [form] = Form.useForm();

	const { options: { tags }} = useOptions();

	const [tagGroup, setTagGroup] = useState({state:[], shape:[], region:[], range:[]});
	const [photoList, setPhotoList] = useState<any[]>([]);

	const galleryFormRef = useRef<any>();
	const [isRquesting, setIsRequesting] = useState(false);


	console.log('dataId --->', dataId);

    
	useEffect(()=>{
		console.log('tags ==>', tags);
		if(tags){
			const state = tags.filter((ele:any)=>ele.tag_type == 'state');
			const shape = tags.filter((ele:any)=>ele.tag_type == 'shape');
			const region = tags.filter((ele:any)=>ele.tag_type == 'region');
			const range = tags.filter((ele:any)=>ele.tag_type == 'range');
			setTagGroup({ state, shape, region, range });
		}

	}, [tags]);


	useEffect(()=>{
		console.log('dataId ==>', dataId);
		if(dataId){
			//get model home detail
			getModelHomeById(dataId).then((res:any)=>{

				const formData = res.body;
				formData.tags.forEach((tag:any) => {
					if(tag.tag_type == 'region'){
						formData.region_id = tag.tag_id;
					}
					if(tag.tag_type == 'shape'){
						formData.shape_id = tag.tag_id;
					}
					if(tag.tag_type == 'state'){
						formData.state_id = tag.tag_id;
					}
					if(tag.tag_type == 'range'){
						formData.range_id = tag.tag_id;
					}
				});

				setPhotoList(formData.photos);

				console.log('formData ===>', formData);
				form.setFieldsValue(formData);

			}).catch((err:any)=>{
				console.log(err);
			});
		}
	}, [dataId]);

	const submitForm = () => {
		form.submit();
	};

	const onSubmitForm = async (values:any) => {
		const tagArray = [values.state_id, values.region_id];
		if (values.shape_id) tagArray.push(values.shape_id);
		if (values.range_id) tagArray.push(values.range_id);
		const data = { ...values, tags: tagArray};
		if (!data.active) data.active = false;
		if(galleryFormRef.current){
			const photos = galleryFormRef.current.getPhotos();
			console.log('photos ===>', photos);
			const pIds:any = [];
			photos.forEach((element:any) => {
				pIds.push(element.id);
			});
			data.photos = pIds;
		}

		console.log('data ==>', data);

		// return;

		setIsRequesting(true);

		if(dataId == 0){
			try {
				const response = await createModelHome(data);
				notification.open({
					type:'success',
					message: 'Message',
					description:'Created succesfully',
					onClick: () => {
						console.log('Notification Clicked!');
					},
				});
				onChange(response.body.insertId);
			}catch(error:any){
				console.log('error ==>', error);
			}
		}else{
			try {
				await updateModelHome(dataId, data);
				notification.open({
					type:'success',
					message: 'Message',
					description:'updated succesfully',
					onClick: () => {
						console.log('Notification Clicked!');
					},
				});
			}catch(error:any){
				console.log('error ==>', error);
			}
		}
		setIsRequesting(false);
		console.log('onSubmitForm ===>', data);
		onChange();

	};


	return (
		<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitForm}>
			<Form.Item
				label="Title"
				name="title"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Sub Title"
				name="sub_title"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Location"
				name="location"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Region"
				name="region_id"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Select>
					{
						tagGroup.region.map((ele:any)=>(
							<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
						))
					}
				</Select>
			</Form.Item>

			<Form.Item
				label="State"
				name="state_id"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Select>
					{
						tagGroup.state.map((ele:any)=>(
							<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
						))
					}
				</Select>
			</Form.Item>

			<Form.Item
				label="Shape"
				name="shape_id"
				rules={[{ required: false, message: 'required!' }]}
			>
				<Select>
					{
						tagGroup.shape.map((ele:any)=>(
							<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
						))
					}
				</Select>
			</Form.Item>

			<Form.Item
				label="Range"
				name="range_id"
				rules={[{ required: false, message: 'required!' }]}
			>
				<Select>
					{
						tagGroup.range.map((ele:any)=>(
							<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
						))
					}
				</Select>
			</Form.Item>

			<Form.Item
				label="Active"
				name="active"
				valuePropName="checked"
			>
				<Checkbox />
			</Form.Item>
						
			<GalleryFrom ref = {galleryFormRef} photoList = {photoList} />

			<div style = {{textAlign:'right'}}>
				<Button type = "primary" onClick = {submitForm} loading = {isRquesting}>Save</Button>
			</div>
		</Form>
	);
};

export default Index;