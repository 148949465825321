import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAll = (offset, limit, query) => {
	return getRequest(`${config.baseURL}housetypes/`);
};

export const createHousetype = (payload) => {
	return postRequest(`${config.baseURL}housetypes/`, payload);
};

export const deleteHousetype = (id) => {
	return deleteRequest(`${config.baseURL}housetypes/${id}`);
};

export const updateHousetype = (id, payload) => {
	return putRequest(`${config.baseURL}housetypes/${id}`, payload);
};

export const getHousetypeById = (id) => {
	return getRequest(`${config.baseURL}housetypes/ids/${id}`);
};

export const getStairTypes = () => {
	return getRequest(`${config.baseURL}housetypes/stairTypes`);
};