import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAllTags = (offset=0, limit=20, query='') => {
	return getRequest(`${config.baseURL}tags/`);
};

export const createTag = (payload) => {
	return postRequest(`${config.baseURL}tags/`, payload);
};

export const deleteTag = (id) => {
	return deleteRequest(`${config.baseURL}tags/${id}`);
};

export const updateTag = (id, payload) => {
	return putRequest(`${config.baseURL}tags/${id}`, payload);
};

export const getTagDetail = (id) => {
	return getRequest(`${config.baseURL}tags/ids/${id}`);
};