import React, { useState, useEffect } from 'react';
import { DeleteOutlined, EditOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { getAllModelHomes, deleteModelHome, updateOrderids } from 'api/api-model-homes';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, Badge } from 'antd';
import HomeModelForm from './HomeModelForm';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';


const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItemTr = SortableElement((props:any) => <tr {...props} />);
const SortableContainerTBody = SortableContainer((props:any) => <tbody {...props} />);

const confirm = Modal.confirm;


const ActionCell = ({ item, onChange }: any) => {
	const [isRequesting, setIsRequesting] = useState(false);

	const onClickBtn = () => {

		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				deleteModelHome(item.id).then((res) => {
					setIsRequesting(false);
					onChange();
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	
	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<DeleteOutlined />} onClick={onClickBtn} />
		</Space>
	);
};

const Index = () => {
	const [tableData, setTableData] = useState<any>([]);
	const [expandedKeys, setExpancedKeys] = useState<any>([0]);
	const [isRquesting, setIsRequesting] = useState(false);
	const [query, setQuery] = useState('');

	const loadTable = () => {
		getAllModelHomes().then((res) => {
			console.log('ProductAPI ===>', res.body);
			setTableData(res.body);
		});
	};

	const columns = [
		{
			render: () => <DragHandle />,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			// sorter: (a:any, b:any) => ('' + a.attr).localeCompare(b.attr),
		},
		{
			title: 'Location',
			dataIndex: 'location',
			key: 'location',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text: string, item: any) => (item.active)?<Badge status = "success"/>:<Badge status = "default" />,
		},
		{
			title: 'Action',
			key: 'action',
			// width: '20%',
			render: (text: string, record: any) => (
				<ActionCell item={record} onChange={() => loadTable()} />
			),
		},
	];

	useEffect(() => {
		loadTable();
	}, []);

	const onCreateNewModelHome = () => {
		const newData = {
			id: 0,
			title:'New Home Model'
		};
		setTableData([newData, ...tableData]);
		setExpancedKeys([newData.id, ...expandedKeys]);
		// window.location.href(`${match.path}/0`);
	};


	const DraggableBodyRow = ({ ...restProps }) => {
		// const { dataSource } = this.state;
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = tableData.findIndex((item:any) => item.id === restProps['data-row-key']);
		return <SortableItemTr index={index} {...restProps} />;
	};

	const onSortEnd = ({ oldIndex, newIndex }:any) => {
		if (oldIndex !== newIndex) {
			const newArr = Array.from(tableData);
			const newData = arrayMoveImmutable(newArr, oldIndex, newIndex).filter((el:any) => !!el);
			console.log('Sorted items: ', newData);
			setTableData(newData);
		}
	};


	const DraggableContainer = (props:any) => (
		<SortableContainerTBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const onChangeSearchInput = (e:any) => {

		setQuery(e.target.value);
		
	};

	const saveOrderStatus = async () => {
		const arr:any[] = [];
		tableData.forEach((element:any) => {
			arr.push(element.id);
		});
		const ids = arr.join(',');
		setIsRequesting(true);
		try {
			await updateOrderids({ids});
		}catch(err){
			console.log(err);
		}
		setIsRequesting(false);
	};

	const ftableData = tableData.filter((ele:any)=>ele.title.toLowerCase().includes(query.toLowerCase()));

	return (
		<Card title = "Metricon Homes" extra = {<><Button onClick={onCreateNewModelHome} type = "primary">Add More</Button> <Button style = {{marginLeft:8}} onClick = {saveOrderStatus} loading = {isRquesting}>Save</Button></>} >
			<div className ="table-header">
				<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
			</div>
			<Table columns={columns} dataSource={ftableData} expandable={{
				expandIconColumnIndex: 4,
				expandedRowKeys: expandedKeys,
				expandIcon: ({ expanded, onExpand, record }) =>
					expanded ? (
						<Button onClick={e => {
							const newArr = expandedKeys.filter((ele:any)=>ele !=  record.id);
							setExpancedKeys(newArr);
							onExpand(record, e);
							onExpand(record, e);
						}} icon = {<CloseOutlined/>} />
					) : (
						<Button onClick={e => {
							setExpancedKeys([expandedKeys, record.id]);
							onExpand(record, e);
						}} icon = {<EditOutlined />} />
					),
				expandedRowRender: record => <HomeModelForm dataId = {record.id} onChange = {(newId)=>{
					if(newId){
						setExpancedKeys([...expandedKeys, newId]);
					}
					loadTable();
				}}/>,
			}}
			rowKey = "id"
			components={{
				body: {
					wrapper: DraggableContainer,
					row: DraggableBodyRow,
				},
			}}
			pagination = {{showSizeChanger:true, pageSizeOptions:['10','20','50', '100'], defaultPageSize:100}}
			/>
		</Card>
	);
};

export default Index;
