
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

interface Props {
    sayduck_product_uuid:string,
	onImported:(data:any[])=>void;
}
export default ({sayduck_product_uuid, onImported}:Props) => {
	const [view3D, setView3D] = useState<any>();
	const [newMaterials, setNewMaterials] = useState<any[]>([]);

	useEffect(() => {
		function loadView() {
			return (
				<>
					<div id="sayduck-3d-viewer-container" style={{ minHeight: '350px', minWidth: '300px', height: '100%', width: '100%', backgroundColor:'#ECEEEF' }}
						data-product-uuid={sayduck_product_uuid}
						data-viewer-options='{
                        "appearance": {
                            "background": "transparent",
                            "hide-photo-studio": true,
                            "hide-embed": true
                        }
                        }'
					/>
				</>
			);
		}
		setView3D(loadView());

		const script = document.createElement('script');
		script.src = 'https://viewer.sayduck.com';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [sayduck_product_uuid]);

	const receiveMessage = (event: any) => {
		const response = event.detail;
		//parse data
		const _newmaterials:any[] = [];
		Object.keys(response).map((key:any)=>{
			if(response[key].metadata.ID){
				_newmaterials.push({label:response[key].label, ...response[key].metadata});
			}
		});
		setNewMaterials(_newmaterials);
		onImported(_newmaterials);
	};

	useEffect(()=>{
		document.addEventListener('sayduck.configurator.selectedConfigurationUpdated', receiveMessage, false);
		return () => {
			document.removeEventListener('sayduck.configurator.selectedConfigurationUpdated', receiveMessage);
		};
	}, [sayduck_product_uuid]);

	const columns = [
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'ID',
			dataIndex: 'ID',
			key: 'ID',
		},
		{
			title: 'Material',
			dataIndex: 'Material',
			key: 'Material',
		},
		{
			title: 'Group',
			dataIndex: 'Group',
			key: 'Group',
		},
		{
			title: 'Part',
			dataIndex: 'Part',
			key: 'Part',
		}
	];

	return (
		<>
			<div className="sayduck-3d-viewer-wrap" style={{ position: 'relative', display: 'flex', height:'100%', marginTop:16 }}>
				{view3D}
			</div>
			<div>
				Exported Materials
				<Table columns={columns} dataSource={newMaterials} />
			</div>
		</>
	);
};