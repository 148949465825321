import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAll = (type, offset=0, limit=10000, query='') => {
	return getRequest(`${config.baseURL}pricing/${type}/?offset=${offset}&limit=${limit}&query=${query}`);
};

export const getDetailById = (id) => {
	return getRequest(`${config.baseURL}pricing/${type}/${id}`);
};

export const update = (type, id, data) => {
	return putRequest(`${config.baseURL}pricing/${type}/${id}`, data);
};

export const remove = (type, id) => {
	return deleteRequest(`${config.baseURL}pricing/${type}/${id}`);
};

export const create = (type, data) => {
	return postRequest(`${config.baseURL}pricing/${type}`, data);
};