import React, { useEffect, useRef, useState } from 'react';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, InputNumber, Badge, Select, InputRef } from 'antd';
import * as PricingAPI from 'api/api-pricing';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import classes from './style.module.scss';
import { formatPrice } from 'utils';
import useOptions from 'modules/options/options.hook';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

interface DataType {
	key: string;
	balustrade_type: string;
	state_tag_id: string;
	finish: string;
	config_hand_rail: string;
	config_post: string;
	config_post_top: string;
	config_balustrade: string;
	finish_hand_rail: string;
	finish_post: string;
	finish_post_top: string;
	finish_balustrade: string;
}
type DataIndex = keyof DataType;
const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
    id: number;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form:any;
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'price' | 'select' | 'tag' |'feature_tag';
    record: Item;
    index: number;
    children: React.ReactNode;
	options?:any[]
}

const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	options,
	...restProps
}) => {

	let inputNode = <Input />;

	if(inputType == 'number'){
		inputNode = <InputNumber min = {0} />;
	}
	if(inputType == 'select'){
		inputNode = <Select>{options?options.map((ele)=><Option value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'feature_tag'){
		inputNode = <Select mode="multiple">{options?options.map((ele)=><Option key = {ele} value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'tag'){
		inputNode = <Select>{options?options.map((ele)=><Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}
	if(inputType == 'price'){
		inputNode = <Input type = "number" min = {0} suffix = "$"/>;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {

	
	const [isRequesting, setIsRequesting] = useState(false);
	const onDeleteBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				PricingAPI.remove('balustrade', item.id).then((res:any)=>{
					setIsRequesting(false);
					onChange();	
				}).catch((err:any)=>{
					setIsRequesting(false);
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = async () => {
		setIsRequesting(true);
		console.log('onSave ==>', form.getFieldsValue());

		const data = form.getFieldsValue();
		data.feature_variants = data.feature_variants.join(',');

		try{
			if(editingId>0){
				await PricingAPI.update('balustrade', editingId, data);
			}else{
				await PricingAPI.create('balustrade', data);
			}
		}catch(err:any){
			console.log(err);
		}
		setIsRequesting(false);
		onEditing(-1);
		form.resetFields();
		onChange();
	};

	const onCancel = () => {
		onEditing(-1);
	};

	if(editingId == item.id){
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined/>} onClick={onCancel} />
			</Space>
		);    
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined/>} onClick={onDeleteBtn} />
		</Space>
	);
};

export default function(){


	const [form] = Form.useForm();
	const [editingId, setEditingId] = useState(-1);
	const [tableData, setTableData] = useState<any>([]);
	const [query, setQuery] = useState('');
	const { options: { tags }} = useOptions();
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef<InputRef>(null);

	const handleSearch = (
		selectedKeys: string[],
		confirm: (param?: FilterConfirmProps) => void,
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	
	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText((selectedKeys as string[])[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()),
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	useEffect(()=>{
		loadTable();
	}, []);

	const loadTable = () => {
		PricingAPI.getAll('balustrade').then((res:any)=>{
			console.log('PricingAPI.getAll ===>', res.body);
			res.body.data.forEach((element:any) => {
				element.feature_variants = element.feature_variants.split(',');
			});
			setTableData(res.body.data);
		}).catch((err:any)=>{
			console.log('ERROR ==>', err);
		});
	};

	const onChangeSearchInput = (e:any) => {
		setQuery(e.target.value);
	};

	const onCreateNewPricingData = () => {
		setEditingId(0);
		const newData = {
			id: 0,
			balustrade_type:'',
			feature_variants:[],
		};
		if(tableData.findIndex((ele:any)=>ele.id == 0) == -1){
			setTableData([newData, ...tableData]);
		}
	};

	const getStateTagFilter = ()=>{
		const tagArr = tags.filter((ele:any)=>ele.tag_type == 'state');
		return tagArr.map((ele:any)=>({text:ele.tag_name, value:ele.id}));
	};

	const getShapeVariantsFilter = ()=>{
		const tagArr = tags.filter((ele:any)=>ele.tag_type == 'state');
		const filterArr = tagArr.map((ele:any)=>({text:ele.tag_name, value:ele.id}));
		return filterArr;
	};

	const columns = [
		{
			title: 'Classification',
			className: classes.classification,
			children: [
				{
					title: 'Balustrade Type',
					dataIndex: 'balustrade_type',
					key: 'balustrade_type',
					editable:true,
					//fixed:'left',
					...getColumnSearchProps('balustrade_type'),
					sorter: (a:any, b:any) => ('' + a.balustrade_type).localeCompare(b.title),
				},
				{
					title: 'state',
					dataIndex: 'state_tag_id',
					key: 'state_tag_id',
					inputType:'tag',
					options:tags.filter((ele:any)=>ele.tag_type == 'state'),
					editable:true,
					filters:getStateTagFilter(),
					onFilter: (value: string, record:any) => {
						return (record.state_tag_id == value);
					},
					render:(text:string, item:any)=>{
						const arr:any = [];
						const tagArr = tags.filter((ele:any)=>ele.tag_type == 'state');
						const tag = tagArr.find((tag:any)=>(tag.id == item.state_tag_id));
						if(tag){
							return tag.tag_name;
						}else{
							return null;
						}
					}
				},
				{
					title: 'Finish',
					dataIndex: 'finish',
					key: 'finish',
					...getColumnSearchProps('finish'),
					editable:true,
				}
			],
			editable:true,
		},
		{
			title: 'Configuration',
			className: classes.configuration,
			children: [
				{
					title: 'Hand Rail',
					dataIndex: 'config_hand_rail',
					key: 'config_hand_rail',
					...getColumnSearchProps('config_hand_rail'),
					// inputType:'select',
					// options: ['No', 'Yes 1', 'Yes 2'],
					editable:true,
				},
				{
					title: 'Post',
					dataIndex: 'config_post',
					key: 'config_post',
					...getColumnSearchProps('config_post'),
					editable:true,
				},
				{
					title: 'Post Top',
					dataIndex: 'config_post_top',
					key: 'config_post_top',
					editable:true,
					...getColumnSearchProps('config_post_top'),
				},
				{
					title: 'Balustrade',
					dataIndex: 'config_balustrade',
					key: 'config_balustrade',
					...getColumnSearchProps('config_balustrade'),
					editable:true,
				},
			],
			editable:true,
		},
		{
			title: 'Finish',
			className: classes.finish,
			children: [
				{
					title: 'Hand Rail',
					dataIndex: 'finish_hand_rail',
					key: 'finish_hand_rail',
					// inputType:'select',
					// options: ['No', 'Yes 1', 'Yes 2'],
					...getColumnSearchProps('finish_hand_rail'),
					editable:true,
				},
				{
					title: 'Post',
					dataIndex: 'finish_post',
					key: 'finish_post',
					...getColumnSearchProps('finish_post'),
					editable:true,
				},
				{
					title: 'Post Top',
					dataIndex: 'finish_post_top',
					key: 'finish_post_top',
					...getColumnSearchProps('finish_post_top'),
					editable:true,
				},
				{
					title: 'Balustrade',
					dataIndex: 'finish_balustrade',
					key: 'finish_balustrade',
					...getColumnSearchProps('finish_balustrade'),
					editable:true,
				},
			],
			editable:true,
		},
		{
			title: 'Pricing',
			className: classes.pricing,
			children: [
				{
					title: 'Bal_M',
					dataIndex: 'bal_m',
					key: 'bal_m',
					inputType:'price',
					editable:true,
					render:(text:string, item:any)=>{
						return formatPrice(Number(text));
					}
				},
				// {
				// 	title: 'Straight',
				// 	dataIndex: 'straight',
				// 	key: 'straight',
				// 	inputType:'price',
				// 	editable:true,
				// },
				// {
				// 	title: 'Shape',
				// 	dataIndex: 'shape',
				// 	key: 'shape',
				// 	editable:true,
				// },
				{
					title: 'Handrail(B ONLY)',
					dataIndex: 'handrail_B',
					key: 'handrail_B',
					inputType:'price',
					editable:true,
					render:(text:string, item:any)=>{
						return formatPrice(Number(text));
					}
				},
				{
					title: 'Price',
					dataIndex: 'price',
					key: 'price',
					inputType:'price',
					editable:true,
					render:(text:string, item:any)=>{
						return formatPrice(Number(text));
					}
				},
				{
					title: 'Shape Variants',
					dataIndex: 'feature_variants',
					key: 'feature_variants',
					inputType:'feature_tag',
					options: [
						'L Shape',
						'Straight',
						'U Shape Landing (A)',
						'U Shape Landing (A)(+)',
						'U Shape Landing (B)', 
						'U Shape Landing (C)', 
						'U Shape Landing (A)(E)', 
						'U Shape Landing (A)(I)',
						'U Shape Landing (A)(I)(+)',
						'Winders (A)', 
						'Winders (B)', 
						'Winders (C)', 
						'Winders (A)(E)', 
						'Winders (A)(I)',
					],
					filters: [
						{
							text:'L Shape',
							value:'L Shape'
						},
						{
							text:'Straight',
							value:'Straight'
						},
						{
							text:'U Shape Landing (A)',
							value:'U Shape Landing (A)'
						},
						{
							text:'U Shape Landing (A)(+)',
							value:'U Shape Landing (A)(+)'
						},
						{
							text:'U Shape Landing (B)',
							value:'U Shape Landing (B)'
						},
						{
							text:'U Shape Landing (C)',
							value:'U Shape Landing (C)'
						},
						{
							text:'U Shape Landing (A)(E)',
							value:'U Shape Landing (A)(E)'
						},
						{
							text:'U Shape Landing (A)(I)',
							value:'U Shape Landing (A)(I)'
						},
						{
							text:'U Shape Landing (A)(I)(+)',
							value:'U Shape Landing (A)(I)(+)'
						},
						{
							text:'Winders (A)',
							value:'Winders (A)'
						},
						{
							text:'Winders (B)',
							value:'Winders (B)'
						},
						{
							text:'Winders (C)',
							value:'Winders (C)'
						},
						{
							text:'Winders (A)(E)',
							value:'Winders (A)(E)'
						},
						{
							text:'Winders (A)(I)',
							value:'Winders (A)(I)'
						},
					],
					editable:true,
					onFilter: (value: string, record:any) => {
						return (record.feature_variants.indexOf(value) == 0);
					},
					render:(text:string, item:any)=>{
						const arr:any = [];
						return item.feature_variants.join(',');
					}
				}
			],
			editable:true,
		},
		{
			title: 'ACTIONS',
			className: classes.tableActions,
			children: [
				{
					title: 'Action',
					key: 'action',
					//fixed:'right',
					render: (text: string, record: any) => (
						<ActionCell 
							form = {form}
							item={record}
							editingId = {editingId}  
							onChange={() => loadTable()} 
							onEditing = {(val:number)=>{
								setEditingId(val);
								if(val < 0 && editingId == 0){
									tableData.shift();
									setTableData([...tableData]);
								}
							}}
						/>
					),
				},
			],
			editable:true,
		},
	];

	const mapColumns = (col:any) => {
		if (!col.editable) {
			return col;
		}
		const newCol = {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				inputType: (col.inputType)?col.inputType:'text',
				dataIndex: col.dataIndex,
				options:col.options,
				title: col.title,
				editing: (record.id == editingId),
			}),
		};
		if (col.children) {
			newCol.children = col.children.map(mapColumns);
		}
		return newCol;
	};

	const mergedColumns = columns.map(mapColumns);

	const ftableData = tableData.filter((ele:any)=>ele.balustrade_type.toLowerCase().includes(query.toLowerCase()));

	return(
		<>
			<Card title="Balustrade Pricing" extra = {<><Button onClick={onCreateNewPricingData} type = "primary">Add More</Button></>}>
				<div className ="table-header">
					<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
				</div>
				<Form form = {form} >
					<Table
						size="small"
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						bordered
						scroll = {{x:'2000px'}}
						columns={mergedColumns}
						dataSource={ftableData}
						pagination = {{showSizeChanger:true, pageSizeOptions:['10','20','50', '100'], defaultPageSize:100}}
					/>
				</Form>

			</Card>
		</>
	);
}