import React, { useState, useRef } from 'react';
import { Modal, Input } from 'antd';
import SayDuck3DView from './SayDuck3DView';
import  { addBulkMaterials } from 'api/api-materials';
import { getUniqueItemsByProperties } from 'utils';

interface Props {
	open:boolean;
	onClose:(reload:boolean)=>void;
}

const Index = ({open, onClose}:Props) => {

	const [isRequesting, setRequesting] = useState(false);

	//24891140-144a-013a-09d2-767740d404fc
	const [sayduck_uuid, setSayduckUUID] = useState();
	const [newSayduckUUID, setNewSayduckUUID] = useState();
	const [newMaterials, setNewMaterials] = useState<any[]>([]);

	const onClickedOkBtn = async () => {
		console.log('----onClickedOkBtn----');
		setRequesting(true);
		let i,j, temporary;
		const chunk = 100;
		for (i = 0,j = newMaterials.length; i < j; i += chunk) {
			temporary = newMaterials.slice(i, i + chunk);
			// do whatever
			try{
				await addBulkMaterials({materials:temporary});
			}catch(error){
				console.log(error);
			}
		}
		setRequesting(false);
		onClose(true);
	};
	
	const onClickedCancelBtn = () => {
		onClose(false);
	};

	return (
		<>
			<Modal 
				title = "Import 3D model"
				width = "1000px"
				centered
				okButtonProps = {{loading:isRequesting}}
				visible = {open} onOk ={onClickedOkBtn} onCancel = {onClickedCancelBtn}>
				<Input.Search placeholder="input 3D model uuid" onSearch={()=>{
					console.log('NEW UUID ==>', sayduck_uuid);
					// setNewModelUUID(form.getFieldValue('sayduck_uuid'));
					setNewSayduckUUID(sayduck_uuid);
				}}
				onChange = {(e:any)=>{
					setSayduckUUID(e.target.value);
				}}
				enterButton = "load"
				/>
				<br />
				{newSayduckUUID && <SayDuck3DView sayduck_product_uuid = {newSayduckUUID} onImported = {(_newmaterilas)=>{

					console.log(_newmaterilas);
					const newArr = getUniqueItemsByProperties(_newmaterilas, ['ID', 'Group','label','Part', 'Material']);
					console.log(newArr);
					setNewMaterials(newArr);


				}} />}
			</Modal>
		</>
	);
};

export default Index;