import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from 'pages/login';
import Dashboard from 'pages/dashboard';

import { PATHS } from 'constants/routes';
import { AuthLayout } from 'pages/auth';

import ModelHomes from 'pages/modelHomes';

import Users from 'pages/users';
import UserForm from 'pages/users/UserForm';

import Tags from 'pages/tags';
import TagForm from 'pages/tags/TagForm';

import HouseTypes from 'pages/houseTypes';
import HouseTypeForm from 'pages/houseTypes/HouseTypeForm';

import Styles from 'pages/styles';
import StylesForm from 'pages/styles/StylesForm';

import Materials from 'pages/materials';

import BalustradePricing from 'pages/pricing/Balustrade';
import StairPricing from 'pages/pricing/Stair';
import StainPricing from 'pages/pricing/Stain';
import UpgradesPricing from 'pages/pricing/Upgrades';
import TravelPricing from 'pages/pricing/Travel';

function App() {
	console.log('app loading ...');
	return (
		<>
			<AuthLayout />
			<Routes>
				<Route path={PATHS.LOGIN} element={<LoginPage />} />
				<Route path={`${PATHS.DASHBOARD}`} element={<Dashboard />}>
					<Route path={`${PATHS.DASHBOARD}${PATHS.BUILDERS}`} element={<ModelHomes />} />
					<Route path={`${PATHS.DASHBOARD}${PATHS.USERS}`} element={<Users />} />
					<Route path={`${PATHS.DASHBOARD}${PATHS.USERS}/:userId`} element={<UserForm />} />

					<Route path={`${PATHS.DASHBOARD}${PATHS.TAGS}`} element={<Tags />} />
					<Route path={`${PATHS.DASHBOARD}${PATHS.TAGS}/:tagId`} element={<TagForm />} />

					<Route path={`${PATHS.DASHBOARD}${PATHS.HOUSETYPES}`} element={<HouseTypes />} />

					<Route
						path={`${PATHS.DASHBOARD}${PATHS.HOUSETYPES}/:housetypeId`}
						element={<HouseTypeForm />}
					/>

					<Route path={`${PATHS.DASHBOARD}${PATHS.STYLES}`} element={<Styles />} />

					<Route path={`${PATHS.DASHBOARD}${PATHS.STYLES}/:stylesId`} element={<StylesForm />} />

					<Route path={`${PATHS.DASHBOARD}${PATHS.MATERIALS}`} element={<Materials />} />

					<Route
						path={`${PATHS.DASHBOARD}${PATHS.PRICING_BALUSTRADE}`}
						element={<BalustradePricing />}
					/>
					<Route path={`${PATHS.DASHBOARD}${PATHS.PRICING_STAIR}`} element={<StairPricing />} />
					<Route path={`${PATHS.DASHBOARD}${PATHS.PRICING_STAIN}`} element={<StainPricing />} />
					<Route
						path={`${PATHS.DASHBOARD}${PATHS.PRICING_UPGRADES}`}
						element={<UpgradesPricing />}
					/>
					<Route path={`${PATHS.DASHBOARD}${PATHS.PRICING_TRAVEL}`} element={<TravelPricing />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
